<template>
  <div>
    <div class="container">
      <div class="header">
        <div class="search" v-if="search">
          <v-text-field
            dense
            placeholder="Search"
            v-model="query"
            prepend-icon="mdi-magnify"
          ></v-text-field>
        </div>
      </div>
      <div class="select-container">
        <v-checkbox
          v-if="!query"
          class="ml-2"
          dense
          color="success"
          :input-value="areAllSelected"
          label="Select All"
          @click="selectAll"
        ></v-checkbox>
        <div v-for="item in itemsComputed" :key="getItemValue(item)">
          <slot name="item" :item="item">
            <v-checkbox
              class="ml-2"
              @click="addEmailToList(item)"
              dense
              :input-value="isSelected(getItemValue(item))"
              :label="getItemText(item)"
            >
              <template #label>
                <slot name="item-label" :item="item"></slot>
              </template>
            </v-checkbox>
          </slot>
        </div>
      </div>

      <hr class="mb-3" />

      <div class="footer">
        <slot name="footer" :selected="selected.length">
          <div>{{ selected.length }} Lists selected</div>
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
export default {
  props: {
    value: {
      type: Array,
      default: null
    },
    items: {
      type: [Array],
      required: true
    },
    itemText: {
      type: String,
      default: 'text'
    },
    itemValue: {
      type: String,
      default: 'value'
    },
    search: {
      type: Boolean,
      default: false
    },
    sort: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      all: false,
      query: '',
      selected: []
    };
  },
  computed: {
    areAllSelected() {
      if (!this.selected.length) {
        return;
      }
      const selected = [...this.selected];
      return _.isEqual(this.itemsValue().sort(), selected.sort());
    },
    itemsComputed() {
      let result = [...this.items];
      if (this.query) {
        result = result.filter(this.searchItems);
      }
      result.sort(this.cmp);
      if (this.sort) {
        result.sort((a, b) => {
          const valA = typeof a === 'string' ? a : a[this.itemValue];
          const valB = typeof b === 'string' ? b : b[this.itemValue];
          if (this.selected.includes(valA) && !this.selected.includes(valB)) {
            return -1;
          }
          if (!this.selected.includes(valA) && this.selected.includes(valB)) {
            return 1;
          }
          return 0;
        });
      }
      return result;
    }
  },
  watch: {
    selected: {
      handler(newValue) {
        this.$emit('input', newValue);
      }
    },
    value: {
      handler(newValue) {
        this.selected = newValue;
      }
    }
  },
  created() {
    if (this.value) {
      this.selected = [...this.value];
    } else {
      this.$emit('input', []);
    }
  },
  methods: {
    cmp(a, b) {
      if (
        this.getItemValue(a).toLowerCase() < this.getItemValue(b).toLowerCase()
      ) {
        return -1;
      }
      if (
        this.getItemValue(a).toLowerCase() > this.getItemValue(b).toLowerCase()
      ) {
        return 1;
      }
      return 0;
    },
    searchItems(item) {
      if (typeof item === 'string') {
        return item.includes(this.query);
      } else {
        return (
          item[this.itemText].includes(this.query) ||
          item[this.itemValue].includes(this.query)
        );
      }
    },
    clear() {
      this.selected = [];
      this.query = '';
    },
    itemsValue() {
      let items = [...this.items];
      items = items.map(i => {
        if (typeof i === 'string') {
          return i;
        } else {
          return i[this.itemValue];
        }
      });
      return items;
    },
    isSelected(value) {
      return this.selected.includes(value);
    },
    getItemText(item) {
      return typeof item === 'string' ? item : item[this.itemText];
    },
    getItemValue(item) {
      return typeof item === 'string' ? item : item[this.itemValue];
    },
    selectAll() {
      if (this.areAllSelected) {
        this.selected = [];
      } else {
        this.selected = [...this.itemsValue()];
      }
    },
    addEmailToList(item) {
      const itemValue = this.getItemValue(item);
      const index = this.selected.findIndex(i => i === itemValue);
      if (index === -1) {
        this.selected.push(itemValue);
      } else {
        this.selected.splice(index, 1);
      }
    }
  }
};
</script>

<style scoped lang="scss">
@import '../../design/_colors.scss';
.container {
  border: 1px solid $border-color;
  border-radius: 5px;
  margin-bottom: 2px;
  margin-top: 2px;
}
hr {
  border: 1px solid $border-color;
  border-top: 0px;
  margin-left: -13px;
  margin-right: -13px;
}
.select-container {
  max-height: 300px;
  overflow: scroll;
}
</style>
